import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterVariant: { 
    firstVariant: "", 
    secondVariant: "",
    productId: "" // Added productId to the initial state
  },
};

export const variantFilterSlice = createSlice({
  name: "variantFilter",
  initialState,
  reducers: {
    setFirstVariant: (state, action) => {
      state.filterVariant.firstVariant = action.payload;
    },
    setSecondVariant: (state, action) => {
      state.filterVariant.secondVariant = action.payload;
    },
    setProductId: (state, action) => {
      state.filterVariant.productId = action.payload; // Added this reducer to set productId
    },
    resetFilterVariant: (state) => {
      state.filterVariant = { // Reset the entire filterVariant object
        firstVariant: '',
        secondVariant: '',
        productId: '' // Reset productId as well
      };
    },
  },
});

export const { setFirstVariant, setSecondVariant, resetFilterVariant, setProductId } = variantFilterSlice.actions;
export default variantFilterSlice.reducer;