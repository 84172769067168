import React, { useEffect, useState } from "react";
import axios from "axios";
import CommonLayout from "../layout/CommonLayout";
import BackClick from "../components/BackClick";
import locationicon from "../assets/images/icons/locationIcon.svg";
import goIcon from "../assets/images/icons/checkCouponArrow.svg";
import { useSelector } from "react-redux";
import { load } from "@cashfreepayments/cashfree-js";
import { useNavigate, useParams } from "react-router-dom";
import randomString from "randomstring";
import { useLocation } from 'react-router-dom';
import AddressModal from "../components/modal/AddressModal";
import { FaEdit } from "react-icons/fa";
import AddressModalEdit from "../components/modal/AddressModalEdit";
import logo from "../assets/images/Blue_transparent.png"

function AddPaymentAddress() {
  const location = useLocation();
  const totalAmount = location.state?.totalAmount;
  const newproducts = location.state?.products;
  console.log("newproducts", newproducts)
  const subtotalData = location.state?.totalquantityprice;
  const discountprice = localStorage.getItem('discountamt');
  const { userId } = useParams();
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [selected, setSelected] = useState(null);
  const token = localStorage.getItem('token');
  const addressList = useSelector((state) => state.address?.addressList);
  const orderDateTime = new Date().toLocaleDateString('en-GB') + ' ' + new Date().toLocaleTimeString('en-GB');
  const [refreshAddresses, setRefreshAddresses] = useState(false);
  const userAvaterImg = localStorage.getItem('storeavater');
  const storeusername = localStorage.getItem('userid');
  const usermobile = localStorage.getItem('usermob');
  const storephone = localStorage.getItem('storephone');
  const storeemail = localStorage.getItem('storeemail');
  const SelectedEmail = localStorage.getItem('selected-email');
  const Selectedaddress = localStorage.getItem('selected-address');
  const selectedName = localStorage.getItem('selected-name');
  const storeid = localStorage.getItem("storeid");
  const [marchentId, setmarchentId] = useState();
  let cartData = JSON.parse(localStorage.getItem("cart")) || [];


  const cartItemsHtml = cartData.map((item) => `
   <tr >
     <td style="width:100px;"><img src="${item.image}" style="width:100px; height:100px;border:1px solid #ddd"></td>
     <td style="font-weight:500;">
       <ul style=" padding: 0; list-style: none;margin:0;display: block;">
         <li style="width:100%; text-align: left; font-weight:600;">${item?.title}</li>
         <li style="width:100%; text-align: left; font-weight:600;">color: ${item?.variant?.secondVariant?.title?.value}</li>
         <li style="width:100%; text-align: left; font-weight:600;">Size: ${item?.variant?.firstVariant?.title?.value}</li>
         <li style="width:100%; text-align: left; font-weight:600;">Quantity: ${item?.quantity}</li>
         <li style="width:100%; text-align: left; font-weight:600;">Amount: ₹${(item?.price * item?.quantity)}</li>
       </ul>
     </td>
  </tr>
  `).join('');

  let cashfree;
  const initializeSDK = async function () {
    cashfree = await load({
      mode: process.env.REACT_APP_CASHFREE_MODE,
    });
  };
  initializeSDK();

  const makePayment = (
    amount = totalAmount,
    orderId = randomString.generate(10),
    customerId = usermobile,
    customerContactNumber = usermobile
  ) => {
    const orderData = {
      order_amount: amount,
      order_currency: "INR",
      order_id: orderId,
      customer_details: {
        customer_id: customerId,
        customer_phone: customerContactNumber,
      },
      order_meta: {
        return_url:
          "https://www.cashfree.com/devstudio/preview/pg/web/popupCheckout?order_id=" +
          orderId,
      },
    };

    axios
      .post(process.env.REACT_APP_BACKEND_URL + "pay", orderData)
      .then(async (response) => {
        await pay(response.data.payment_session_id, orderId, amount);
        localStorage.setItem('OrginalOrderId', orderId);

      })
      .catch((error) => console.log(error));
  };

  const pay = async (paymentSessionId, orderId, amount) => {
    let checkoutOptions = {
      paymentSessionId: paymentSessionId,
      redirectTarget: "_modal",
    };
    cashfree.checkout(checkoutOptions).then(async (result) => {
      console.log(result.error);
      if (result.error) {
        navigate(`/${userId}/paymentfaild`);
        // mailAPI request to send email
        // faild mail to buyer 
        fetch(`${process.env.REACT_APP_BACKEND_URL}mail/sendMail/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: SelectedEmail,
            subject: `Order Failed with ${storeusername} Order Number: ${orderId}`,
            html: `<!DOCTYPE html>
<html>
<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1">
	<title>Order Failure</title>
</head>
<body style="font-family: poppins;">
	<style type="text/css">
		@media(max-width:768px){
			.order {
			    margin: 0 10px!important;
			    padding: 0 10px !important;
			}
		.order table tr th,.order table tr td{
			font-size: 13px;
			        padding: 0.25rem !important;
		   }
		  .order  h6 {
		      font-size: 15px !important;
		       margin: 10px 0 5px !important;

			}
			.order h2 {
			    margin: 5px 0;
			    font-size: 16px;
			}
			.order h2 {
			    margin: 5px 0;
			    font-size: 16px;
			}
			.order p {
			    font-size: 12px;
			   margin: 5px 0!important;
			}	
			.order img{
				width: 90px!important;
			}
			.order h4 {
			    font-size: 15px;
			    padding: 5px 0!important;
			    margin: 5px 0;
			}
			.order ul li {
			    font-size: 11px !important;
			}
	      .sm{
	      	font-size: 10px!important;
	      }
	      .order div {
			    padding: 5px 0 !important;
		  }
		.order  h5 {
		    font-size: 16px !important;
		}
		.product-table tr{
          display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
		}
		   .product-table tr ul li {
		        width: 100% !important;
		        text-align: left !important;
		    }
	}
	</style>
    <section className="order" style="margin: 0 20px;padding: 20px;border: 1px solid #dddd;">
    	<div className="logo" style="text-align:center; " >
		    	 <img src=${userAvaterImg} style="width:100px; padding-top: 20px;">
		    	 <h2 style="margin:0px">${storeusername}</h2>
        </div>
         <table style="">
			  <tr style="">
			    <th style=" margin-bottom: 10px; padding: 0px;">Order Number:</th>
			    <td style="margin-bottom: 10px; padding: 0px;">${orderId}</td>
			  </tr>
			  <tr style="">
			    <th style="text-align: left; margin-bottom: 10px; padding: 0px;">Order Date: </th>
			    <td style="margin-bottom: 10px; padding: 0px;">${orderDateTime}</td>
			  </tr>
		</table>
		<h6 style="text-align:left; font-size:20px; margin: 20px 0 10px;">Dear ${selectedName},</h6>
		<p style="text-align:left;">Oops, your order failed. You can try to place the order again or contact <b>${storeusername}</b> at ${storephone} or email at ${storeemail}</p>
			<h4 style="border-top:1px solid #ddd; border-bottom: 1px solid #ddd; padding:20px 0;">Order Details</h4>
			<div >
				<table style="width:100%;" className="product-table">
					 ${cartItemsHtml}
				</table>
			</div>
			<div style="display:flex; border-top:1px solid #ddd; border-bottom: 1px solid #ddd; padding:20px 0;" >
				 <div style="width:100%;">
				 	 <h4 style="">Order Details</h4>
				 	 <ul style="padding:0; margin:0; list-style: none;">
				 	 	<li style="margin-bottom:5px; font-size: 15px;"><b>Order Type: </b>Prepaid</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;"><b>Payment Made:  </b>${totalAmount - discountprice}</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;"><b>Balance Payment </b>0</li>
				 	 </ul>
           	<ul style="padding:0; margin:0; list-style: none; margin-top: 0px;">
				 	 	<li style="margin-bottom:10px; font-size: 15px;"><b>Sub Total: </b><span ">${subtotalData}</span></li>
				 	 	<li style="margin-bottom:10px; font-size: 15px;"><b>Additional Discount:</b> <span>${discountprice}</span></li>
				 	 	<li style="margin-bottom:	10px; font-size: 15px;"><b>Sum Total: </b><span >${totalAmount - discountprice}</span></li>
				 	 </ul>
				 	 <p style="font-size:12px;" className="sm">(All Payments are inclusive of GST)</p>
				 </div>
			</div>
			<div style=" padding:20px 0;">
				 	 <h4 style="">Shipping Details:</h4>
				 	 <ul style="padding:0; margin:0; list-style: none;">
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${selectedName}</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${Selectedaddress}</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${usermobile}</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${SelectedEmail}</li>
				 	 </ul>
				 	 		   <p>For any clarification, concern or query, please call <b>${storeusername}</b> at  <a href="">${storephone}</a> or email at <a href="">${storeemail}</a></p>
		   </div>
		    <div style="text-align:center;">
		    	<h5 style="font-size:20px; margin:10px 0;">Powered by <a href="">HelloStore™</a> </h5>
		   		<p style="font-size:12px;" className="sm">Disclaimer: The items have been brought by you directly from the seller: ${storeusername} The Seller is responsible for quality, delivery and servicing of the order. HelloStore is a technology provider and has no role to play in order servicing or fulfilment. For any order or servicing related issue, please contact the seller directly.</p>
		    </div>

    </section>
</body>
</html>`,
          }),
        })
          .then(response => response.json())
          .then(data => {
            console.log('Buyer Email failed  successfully', data);
          })
          .catch(error => {
            console.error('Error sending email:', error);
          });


        // faild mail to seller 
        fetch(`${process.env.REACT_APP_BACKEND_URL}mail/sendMail/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: storeemail,
            // to: "mr.sudarshandebnath@gmail.com",
            subject: `New order attempt failed: Order Number: ${orderId}`,
            html: `
            <!DOCTYPE html>
<html>
<head>
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<body style="font-family: poppins;">
<style type="text/css">
@media(max-width:768px){
  .order {
      margin: 0 10px!important;
      padding: 0 10px !important;
  }
.order table tr th,.order table tr td{
  font-size: 13px;
          padding: 0.25rem !important;
   }
.order   h6 {
      font-size: 15px !important;
       margin: 10px 0 5px !important;

  }
.order	h2 {
      margin: 5px 0;
      font-size: 16px;
  }
.order	h2 {
      margin: 5px 0;
      font-size: 16px;
  }
.order	p {
      font-size: 12px;
     margin: 5px 0!important;
  }	
.order	img{
    width: 90px!important;
  }
.order	h4 {
      font-size: 15px;
      padding: 5px 0!important;
      margin: 5px 0;
  }
.order	ul li {
      font-size: 11px !important;
  }
    .sm{
      font-size: 10px!important;
    }
  .order  div {
      padding: 5px 0 !important;
  }
.order  h5 {
    font-size: 16px !important;
}
.product-table tr{
      display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
}
   .product-table tr ul li {
        width: 100% !important;
        text-align: left !important;
    }
}
</style>
<section className="order" style="margin: 0 20px;padding: 20px;border: 1px solid #dddd;">
  <div className="logo" style="text-align:center; " >
       <img src="https://infyedgesolutions.com/Blue_transparent.png" alt="TaptoHello Logo" style="width: 20%; padding-top: 20px; padding-bottom: 10px;">
    </div>
     <table style="">
     <tr style="">
			    <th style=" margin-bottom: 10px; padding: 0px;">Order Number:</th>
			    <td style="margin-bottom: 10px; padding: 0px;">${orderId}</td>
			  </tr>
			  <tr style="">
			    <th style="text-align: left; margin-bottom: 10px; padding: 0px;">Order Date: </th>
			    <td style="margin-bottom: 10px; padding: 0px;">${orderDateTime}</td>
			  </tr>
</table>
<p style="text-align:left;">You have received an order today. Below is the order detail:</p>
  <h4 style="border-top:1px solid #ddd; border-bottom: 1px solid #ddd; padding:20px 0;">Order Details</h4>
  <div >
    <table style="width:100%;" className="product-table">
       ${cartItemsHtml}
    </table>
  </div>
  <div style="display:flex; justify-content:space-between; border-top:1px solid #ddd; border-bottom: 1px solid #ddd; padding:20px 0;" >
     <div style="width:100%;">
        <h4 style="">Order Details</h4>
        <ul style="padding:0; margin:0; list-style: none;">
          <li style="margin-bottom:5px; font-size: 15px;"><b>Order Type: </b>Prepaid</li>
          <li style="margin-bottom:5px; font-size: 15px;"><b>Payment Made:  </b>${totalAmount - discountprice}</li>
          <li style="margin-bottom:5px; font-size: 15px;"><b>Balance Payment </b>0 </li>
        </ul>
          <ul style="padding:0; margin:0; list-style: none; margin-top: 0px;">
          <li style="margin-bottom:10px; font-size: 15px;"><b>Sub Total: </b><span >${subtotalData}</span></li>
          <li style="margin-bottom:10px; font-size: 15px;"><b>Additional Discount:</b> <span >${discountprice}</span></li>
          <li style="margin-bottom:	10px; font-size: 15px;"><b>Sum Total: </b><span >${totalAmount - discountprice}</span></li>
        </ul>
        <p style="font-size:12px;" className="sm">(All Payments are inclusive of GST)</p>
     </div>
    
  </div>
  <div style=" padding:20px 0;">
        <h4 style="">Shipping Details:</h4>
        <ul style="padding:0; margin:0; list-style: none;">
          <li style="margin-bottom:5px; font-size: 15px;">${selectedName}</li>
          <li style="margin-bottom:5px; font-size: 15px;">${Selectedaddress}</li>
          <li style="margin-bottom:5px; font-size: 15px;">${usermobile}</li>
          <li style="margin-bottom:5px; font-size: 15px;">${SelectedEmail}</li>
        </ul>
   </div>
    <div style="text-align:center;">
      <h5 style="font-size:20px; margin:10px 0;">Powered by <a href="">HelloStore™</a> </h5>
    </div>

</section>
</body>
</html>
            `
          }),
        })
          .then(response => response.json())
          .then(data => {
            console.log('seller Email failed  successfully', data);
          })
          .catch(error => {
            console.error('Error sending email:', error);
          });

      } else if (result.paymentDetails) {
        console.log("pay data", result.paymentDetails);

        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}customer/create-order`;
        const payload = {
          products: newproducts.map((item) => ({
            productId: item.id,
            variantId:
              [
                { variantID: item?.variant?.firstVariant?.title?.id },
                { variantID: item?.variant?.secondVariant?.title?.id }
              ]
            ,
            variantName: item?.variant?.firstVariant?.title?.value,
            quantity: item.quantity,

          })),
          addressId: selected,
          amount: amount,
          totalAmt: totalAmount,
          merchantId: orderId,
          sellerId: storeid
        };
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
            body: JSON.stringify(payload),
          });

          const result = await response.json();
          const orderOrginalId = result.order.id;

          // Update order number
          try {
            await fetch(`${process.env.REACT_APP_BACKEND_URL}customer/updateOrderNum`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                token,
              },
              body: JSON.stringify({
                orderId: orderOrginalId,
                orderNumber: orderId,
              }),
            });
          } catch (error) {
            console.error("Error updating order number:", error);
          }



          if (response.ok) {
            const NeworderId = orderId;
            navigate(`/${userId}/order-successfull`, {
              state: {
                neworderId: orderId,
                paymentType: "Prepaid",
                orderDateTime,
                orderAmount: amount,
              },
            });

            // mailAPI request to send email
            // buyer mail send if sucess
            fetch(`${process.env.REACT_APP_BACKEND_URL}mail/sendMail/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                to: SelectedEmail,
                subject: `Order successfully placed with ${storeusername} Order Number: ${NeworderId}`,
                html: `
      <!DOCTYPE html>
<html>
<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1">
	<title>Order Sucess Buyer</title>
</head>
<body style="font-family: poppins;">
	<style type="text/css">
  .im{
  width:60%;
  }
		@media(max-width:768px){
		.order	section {
			    margin: 0 10px!important;
			    padding: 0 10px !important;
			}
		.order table tr th,.order table tr td{
			font-size: 13px;
			        padding: 0.25rem !important;
		   }
		   .order h6 {
		      font-size: 15px !important;
		       margin: 10px 0 5px !important;

			}
		.order	h2 {
			    margin: 5px 0;
			    font-size: 16px;
			}
			.order h2 {
			    margin: 5px 0;
			    font-size: 16px;
			}
		.order	p {
			    font-size: 12px;
			   margin: 5px 0!important;
			}	
	
		.order	img{
				width: 90px!important;
			}
		.order	h4 {
			    font-size: 15px;
			    padding: 5px 0!important;
			    margin: 5px 0;
			}
		.order	ul li {
			    font-size: 11px !important;
			}
	      .sm{
	      	font-size: 10px!important;
	      }
	    .order  div {
			    padding: 5px 0 !important;
		  }
		.order  h5 {
		    font-size: 16px !important;
		}
		.product-table tr{
          display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
		}
		   .product-table tr ul li {
		        width: 100% !important;
		        text-align: left !important;
		    }
	}
	</style>
    <section className="order" style="margin: 0 20px;padding: 20px;border: 1px solid #dddd;">
    	<div className="logo" style="text-align:center; " >
		    	 <img src=${userAvaterImg} style="width: 100px; padding-top: 20px; padding-bottom: 0px;">
		    	 <h2 style=" margin: 0;">${storeusername}</h2>
        </div>
         <table style="">
			  <tr style="">
			    <th style=" margin-bottom: 10px; padding: 0px;">Order Number:</th>
			    <td style="margin-bottom: 10px; padding: 0px;">${NeworderId}</td>
			  </tr>
			  <tr style="">
			    <th style="text-align: left; margin-bottom: 10px; padding: 0px;">Order Date: </th>
			    <td style="margin-bottom: 10px; padding: 0px;">${orderDateTime}</td>
			  </tr>
		</table>
		<h6 style="text-align:left; font-size:20px; margin: 20px 0 10px;">Dear ${selectedName},</h6>
		<p style="text-align:left;">Thank you for shopping with <b>${storeusername}</b>. We wish to confirm that we have received your order.</p>
			<h4 style="border-top:1px solid #ddd; border-bottom: 1px solid #ddd; padding:20px 0;">Order Details</h4>
			<div >
				<table style="width:100%;" className="product-table">
        ${cartItemsHtml}
				</table>
			</div>
			<div style="display:flex;justify-content:space-between; border-top:1px solid #ddd; border-bottom: 1px solid #ddd; padding:20px 0;" >
				 <div style="width:100%;">
				 	 <h4 style="">Order Details</h4>
				 	 <ul style="padding:0; margin:0; list-style: none;">
				 	 	<li style="margin-bottom:5px; font-size: 15px;"><b>Order Type: </b><span style="padding-left:10px;">Prepaid</span></li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;"><b>Payment Made:  </b><span style="padding-left:10px;">${totalAmount - discountprice}</span></li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;"><b>Balance Payment </b><span style="padding-left:10px;">0</span></li>
				 	 </ul>
           	<ul style="padding:0; margin:0; list-style: none; margin-top: 0px;">
				 	 	<li style="margin-bottom:10px; font-size: 15px;"><b>Sub Total: </b><span style="padding-left:10px;">${subtotalData}</span></li>
				 	 	<li style="margin-bottom:10px; font-size: 15px;"><b>Additional Discount:</b> <span style="padding-left:10px;">${discountprice}</span></li>
				 	 	<li style="margin-bottom:	10px; font-size: 15px;"><b>Sum Total: </b><span style="padding-left:10px;">${totalAmount - discountprice}</span></li>
				 	 </ul>
				 	 <p style="font-size:12px;" className="sm">(All Payments are inclusive of GST)</p>
				 </div>
				
			</div>
			<div style=" padding:20px 0;">
				 	 <h4 style="">Shipping Details:</h4>
				 	 <ul style="padding:0; margin:0; list-style: none;">
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${selectedName}</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${Selectedaddress}</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${usermobile}</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${SelectedEmail}</li>
				 	 </ul>
				 	 		   <p>We will pack the order and ship it to you. We will share the tracking details as soon as the order is shipped.</p>
				 	 		   <p>For any clarification, concern or query, please call <b>${storeusername}</b> at  <a href="">${storephone}</a> or email at <a href="">${storeemail}</a></p>
		   </div>
		    <div style="text-align:center;">
		    	<h5 style="font-size:20px; margin:10px 0;">Powered by <a href="">HelloStore™</a> </h5>
		   		<p style="font-size:12px;" className="sm">Disclaimer: The items have been brought by you directly from the seller: ${storeusername} The Seller is responsible for quality, delivery and servicing of the order. HelloStore is a technology provider and has no role to play in order servicing or fulfilment. For any order or servicing related issue, please contact the seller directly.</p>
		   		<img src="https://infyedgesolutions.com/Blue_transparent.png" style="width: 25%; padding-top: 10px; padding-bottom: 10px;">

		    </div>

    </section>
</body>
</html>
      `,
              }),
            })
              .then(response => response.json())
              .then(data => {
                console.log('Buyer Email sent  successfully', data);
              })
              .catch(error => {
                console.error('Error sending email:', error);
              });

            // seller mail send if sucess
            fetch(`${process.env.REACT_APP_BACKEND_URL}mail/sendMail/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                to: storeemail,
                // to: "mr.sudarshandebnath@gmail.com",
                subject: `New order received - Order Number:  ${NeworderId}`,
                html: `
                <!DOCTYPE html>
<html>
<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<body style="font-family: poppins;">
	<style type="text/css">
		@media(max-width:768px){
			.order {
			    margin: 0 10px!important;
			    padding: 0 10px !important;
			}
		.order table tr th,.order table tr td{
			font-size: 13px;
			        padding: 0.25rem !important;
		   }
		.order   h6 {
		      font-size: 15px !important;
		       margin: 10px 0 5px !important;

			}
		.order	h2 {
			    margin: 5px 0;
			    font-size: 16px;
			}
		.order	h2 {
			    margin: 5px 0;
			    font-size: 16px;
			}
		.order	p {
			    font-size: 12px;
			   margin: 5px 0!important;
			}	
		.order	img{
				width: 90px!important;
			}
		.order	h4 {
			    font-size: 15px;
			    padding: 5px 0!important;
			    margin: 5px 0;
			}
		.order	ul li {
			    font-size: 11px !important;
			}
	      .sm{
	      	font-size: 10px!important;
	      }
	    .order  div {
			    padding: 5px 0 !important;
		  }
		.order  h5 {
		    font-size: 16px !important;
		}
		.product-table tr{
          display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
		}
		   .product-table tr ul li {
		        width: 100% !important;
		        text-align: left !important;
		    }
	}
	</style>
    <section className="order" style="margin: 0 20px;padding: 20px;border: 1px solid #dddd;">
    	<div className="logo" style="text-align:center; " >
		    	 <img src="https://infyedgesolutions.com/Blue_transparent.png" alt="TaptoHello Logo" style="width: 20%; padding-top: 20px; padding-bottom: 10px;">
        </div>
         <table style="">
			   <tr style="">
			    <th style=" margin-bottom: 10px; padding: 0px;">Order Number:</th>
			    <td style="margin-bottom: 10px; padding: 0px;">${NeworderId}</td>
			  </tr>
			  <tr style="">
			    <th style="text-align: left; margin-bottom: 10px; padding: 0px;">Order Date: </th>
			    <td style="margin-bottom: 10px; padding: 0px;">${orderDateTime}</td>
			  </tr>
		</table>
		<p style="text-align:left;">You have received an order today. Below is the order detail:</p>
			<h4 style="border-top:1px solid #ddd; border-bottom: 1px solid #ddd; padding:20px 0;">Order Details</h4>
			<div >
				<table style="width:100%;" className="product-table">
					 ${cartItemsHtml}
				</table>
			</div>
			<div style="display:flex;justify-content:space-between; border-top:1px solid #ddd; border-bottom: 1px solid #ddd; padding:20px 0;" >
				 <div style="width:100%;">
				 	 <h4 style="">Order Details</h4>
				 	 <ul style="padding:0; margin:0; list-style: none;">
				 	 	<li style="margin-bottom:5px; font-size: 15px;"><b>Order Type: </b><span style="padding-left:10px;">Prepaid</span></li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;"><b>Payment Made:  </b><span style="padding-left:10px;">${totalAmount - discountprice}</span></li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;"><b>Balance Payment </b><span style="padding-left:10px;">0</span></li>
				 	 </ul>
           	<ul style="padding:0; margin:0; list-style: none; margin-top: 0px;">
				 	 	<li style="margin-bottom:10px; font-size: 15px;"><b>Sub Total: </b><span style="padding-left:10px;">${subtotalData}</span></li>
				 	 	<li style="margin-bottom:10px; font-size: 15px;"><b>Additional Discount:</b> <span style="padding-left:10px;">${discountprice}</span></li>
				 	 	<li style="margin-bottom:	10px; font-size: 15px;"><b>Sum Total: </b><span style="padding-left:10px;">${totalAmount - discountprice}</span></li>
				 	 </ul>
				 	 <p style="font-size:12px;" className="sm">(All Payments are inclusive of GST)</p>
				 </div>
				
			</div>
			<div style=" padding:20px 0;">
				 	 <h4 style="">Shipping Details:</h4>
				 	 <ul style="padding:0; margin:0; list-style: none;">
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${selectedName}</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${Selectedaddress}</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${usermobile}</li>
				 	 	<li style="margin-bottom:5px; font-size: 15px;">${SelectedEmail}</li>
				 	 </ul>
				 	<p>Please pack the order and ship it to the consignee. Please share the tracking details with the buyer as soon as the order is shipped.</p>
		   </div>
		    <div style="text-align:center;">
		    	<h5 style="font-size:20px; margin:10px 0;">Powered by <a href="">HelloStore™</a> </h5>
		    </div>

    </section>
</body>
</html>
                `,
              }),
            })
              .then(response => response.json())
              .then(data => {
                console.log('seller Email sent  successfully', data);
              })
              .catch(error => {
                console.error('Error sending email:', error);
              });
            localStorage.removeItem("cart");

          } else {
            console.error(result);
          }
        } catch (error) {
          console.error(error.message);
        }
      }

    });
  };


  useEffect(() => {
    const fetchAddresses = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}customer/get-all-addresses`, {
          method: 'GET',
          headers: {
            "token": token,
          },
        });
        const data = await response.json();
        setAddresses(data.addresses || []);

      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    };

    fetchAddresses();
  }, [token, refreshAddresses]);


  return (
    <CommonLayout>
      <BackClick />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px 20px",
          paddingTop: "30px",
          gap: "12px",
        }}
      >
        <button
          style={{
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            border: "none",
            padding: "20px 14px",
            borderRadius: "6px",
            width: "100%",
          }}
          data-bs-toggle="modal"
          data-bs-target="#addressModal"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", gap: "16px" }}>
              <img src={locationicon} alt="" />
              <p
                style={{
                  marginBottom: "0px",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#333",
                }}
              >
                {addressList?.length > 0
                  ? "Deliver at:"
                  : "Add your shipping address"}
              </p>
            </div>
            <div>
              <img src={goIcon} alt="" />
            </div>
          </div>
        </button>

        <AddressModal setRefreshAddresses={setRefreshAddresses} />
        <div className="w-100">
          {addresses?.map((item, i) => (
            <>
              <div key={i} className="d-flex gap-3" style={{
                background: '#fff',
                padding: '15px',
                paddingBottom: '0px',
                borderRadius: '6px',
                marginBottom: '10px'
              }}>
                <div style={{
                  width: '10%',
                  position: 'relative',
                }}>
                  <input
                    type="radio"
                    name="address"
                    style={{
                      transform: 'scale(2)',
                      position: 'absolute',
                      top: '11px',
                      left: '4px',
                    }}
                    defaultChecked={selected === item._id}
                    onChange={() => {
                      setSelected(item._id);
                      localStorage.setItem('selected-email', item.email);
                      localStorage.setItem('selected-address', item.address);
                      localStorage.setItem('selected-name', item.name);
                    }}
                  />
                </div>
                <p style={{ fontSize: '14px', color: '#333', width: '80%' }}>
                  {item.name}, {item.address}, {item.city}, {item.state}, {item.zip} <br />
                  {item.email} <br />
                  {item.mobile}
                </p>
                <p
                  onClick={() => setSelected(item._id)}
                  data-bs-toggle="modal"
                  data-bs-target="#addressModaledit"
                >
                  <FaEdit size={20} />
                </p>
              </div>
            </>
          ))}
        </div>

        {selected && <AddressModalEdit setRefreshAddresses={setRefreshAddresses} addressId={selected} />}

        <button
          style={{
            background: "#fff",
            display: "flex",
            justifyContent: "space-between",
            border: "none",
            padding: "20px 14px",
            borderRadius: "6px",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", gap: "16px" }}>
            <input
              type="radio"
              // name="address"
              id=""
              style={{
                transform: "scale(2)"
              }}
              defaultChecked="true"
            />
            <p
              style={{
                marginBottom: "0px",
                fontSize: "16px",
                fontWeight: "500",
                color: "#333",
              }}
            >
              Online Payment
            </p>
          </div>
          <div>
            <img src={goIcon} alt="" />
          </div>
        </button>

        <button
          style={{
            backgroundColor: selected ? "#3371A5" : "#d3d3d3", // Change color if disabled
            color: "#fff",
            border: "none",
            borderRadius: "10px",
            padding: "14px",
            width: "100%",
            marginTop: "30px",
            fontWeight: "600",
          }}
          onClick={() => { makePayment() }}
          disabled={!selected} // Disable button if no address is selected
        >
          Order Now
        </button>
      </div>
    </CommonLayout>
  );
}

export default AddPaymentAddress;
