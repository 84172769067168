import React, { useEffect, useState } from 'react'
import CommonLayout from '../layout/CommonLayout'
import UseHeader from '../components/UserHeader'
import cart1 from "../assets/images/cart1.png";
import cart2 from "../assets/images/cart2.png";


function OrderConfirm() {
    const [productdata, setProductdata] = useState(null);

    useEffect(() => {
        const fetchSingleProductDetails = async () => {
            const token = localStorage.getItem('token');
            const pid = localStorage.getItem('pId');

            if (!token || !pid) {
                console.error('Token or Product ID is missing');
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}customer/ordersDetails`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                    },
                    body: JSON.stringify({ orderId: pid }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setProductdata(data?.order);
            } catch (error) {
                console.error('Error fetching single product details:', error);
            }
        };

        fetchSingleProductDetails();
    }, []);

    useEffect(() => {
    }, [productdata]);
    return (
        <>
            <CommonLayout>
                <UseHeader />
                <div className='order-cnf mb-3'>
                    <p>Order Number : <span className='font-weight-bold'>{productdata?.orderNumber}</span></p>
                    <p>Order Status : <span className={productdata?.orderStatus === 'New'
                        ? 'text-success'
                        : productdata?.orderStatus === 'Pending'
                            ? 'text-warning'
                            : productdata?.orderStatus === 'failed'
                                ? 'text-danger'
                                : ''}
                    >{productdata?.orderStatus}</span>
                    </p>

                    <p>Order Date : <span>{productdata?.orderDate}</span></p>
                    <p>Customer Name : <span>{productdata?.address?.name}</span></p>
                    <p>Customer Address : <span>{productdata?.address?.address}</span></p>
                    <p>Customer Email :  <span>{productdata?.address?.email}</span></p>
                    <p>Customer Mobile :  <span>{productdata?.address?.mobile}</span></p>
                </div>

                <div className='order-cnf'>
                    {productdata?.products?.map((item, index) => (
                        <>
                            <div className="d-flex">
                                <div className="col-3">
                                    <img
                                        className='rounded'
                                        width={84}
                                        height={93}
                                        src={item?.productImage[0] || "https://via.placeholder.com/84x93"}

                                    />
                                </div>
                                <div className="col-9">
                                    <div className="col-12 d-flex justify-content-between px-2">
                                        <div style={{ display: "inline-block" }}>
                                            <p
                                                style={{
                                                    marginBottom: "0px",
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {item.productTitle}
                                            </p>
                                            <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                                                (Variant Name:
                                                {item?.variantId[0]?.title && item?.variantId[1]?.title
                                                    ? `${item.variantId[0].title} / ${item.variantId[1].title}`
                                                    : item?.variantId[0]?.title || item?.variantId[1]?.title || "No available"}

                                                )
                                            </p>
                                            <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                                                Rs. {item.price === 0 ? item.mrp : item.price}
                                            </p>
                                            <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                                                Qty. {item.quantity}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </>
                    ))}
                    <p className='pb-2'>Payment Type : <span>Prepaid</span></p>
                    <p>Order Total : <span>{productdata?.orderAmount}</span></p>
                </div>
                <div className='my-4 mx-3 order-total'>
                    <h2>Shipment Details</h2>
                    <hr />
                    <div>
                        <div className='row'>
                            <div className='col-6 left-header-layout'>
                                <p>Courier </p>
                            </div>
                            <div className='col-6 right-header-layout'>
                                <p>Delhivery</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6 left-header-layout'>
                                <p>AWB Number</p>
                            </div>
                            <div className='col-6 right-header-layout'>
                                <p>6468263834</p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between bg-white p-3">
                            <p style={{ marginBottom: "0px", fontSize: "14px", fontWeight: '600', color: "rgba(51, 113, 165, 1)" }}>
                                Click here to track the shipment
                            </p>
                            <img src="/static/media/checkCouponArrow.9d6c13e63a766e207ee26a63c5ede1d4.svg" alt="" />
                        </div>
                        <button className='pdf-download'>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 8.00195C18.175 8.01395 19.353 8.11095 20.121 8.87895C21 9.75795 21 11.172 21 14V15C21 17.829 21 19.243 20.121 20.122C19.243 21 17.828 21 15 21H7C4.172 21 2.757 21 1.879 20.122C1 19.242 1 17.829 1 15V14C1 11.172 1 9.75795 1.879 8.87895C2.647 8.11095 3.825 8.01395 6 8.00195" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M11 1V14M11 14L8 10.5M11 14L14 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            PDF Download
                        </button>
                    </div>
                </div>
            </CommonLayout>
        </>
    )
}

export default OrderConfirm