import React, { useState, useEffect } from "react";

function VariantOption({ type, variants, selectedIndex, selectedValue, onVariantClick }) {
  return (
    <>
      {variants.length > 0 && (
        <>
          <div className="Variant-title">
            <h4>{type}</h4>
          </div>
          <div className="Variant-group">
            {variants.map((variant, index) => (
              <div
                key={index}
                style={{ position: "relative" }}
                onClick={() => onVariantClick(type, index, variant)}
                className={`Variant-item mb-2 ${selectedIndex === index ? "active" : ""}`}
              >
                <input
                  type="radio"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: "0px",
                    left: "0px",
                    display: "none",
                  }}
                  name={type.toLowerCase()}
                  value={variant}
                  checked={selectedIndex === index}
                  readOnly
                />
                {variant.value}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

function VariantList({ productVariants, onVariantSelect }) {
  const [selectedVariantIndexes, setSelectedVariantIndexes] = useState({});
  const [selectedVariantValues, setSelectedVariantValues] = useState({});
  const [uniqueVariants, setUniqueVariants] = useState({});
  const [hasChanged, setHasChanged] = useState(false);

  const getUniqueVariants = () => {
    const variantTypes = {};

    productVariants?.forEach((variant) => {
      variant.variants.forEach(({ title: type, value }) => {
        const { title: valueTitle, _id: id } = value;

        if (!variantTypes[type]) {
          variantTypes[type] = [];
        }
        if (!variantTypes[type].some((v) => v.id === id)) {
          variantTypes[type].push({ id, value: valueTitle });
        }
      });
    });

    return variantTypes;
  };


  useEffect(() => {
    const variants = getUniqueVariants();
    setUniqueVariants(variants);

    const initialSelected = {};
    const initialValues = {};

    Object.keys(variants).forEach((type) => {
      if (variants[type].length > 0) {
        initialSelected[type] = 0;
        initialValues[type] = variants[type][0];
      }
    });

    setSelectedVariantIndexes(initialSelected);
    setSelectedVariantValues(initialValues);

    onVariantSelect(initialValues);
  }, [productVariants]);

  const handleVariantClick = (type, index, value) => {
    setHasChanged(true);
    setSelectedVariantIndexes((prev) => ({
      ...prev,
      [type]: index,
    }));
    const updatedValues = {
      ...selectedVariantValues,
      [type]: value,
    };

    setSelectedVariantValues(updatedValues);
    onVariantSelect(updatedValues);
  };

  useEffect(() => {
    if (!hasChanged) {
      onVariantSelect(selectedVariantValues);
    }
  }, [hasChanged, selectedVariantValues, onVariantSelect]);

  useEffect(() => {
    if (!productVariants || productVariants.length === 0) {
      setUniqueVariants({});
      setSelectedVariantIndexes({});
      setSelectedVariantValues({});
    }
  }, [productVariants]);

  return (
    <div className="additionaldetails">
      {Object.keys(uniqueVariants).map((type) => (
        <VariantOption
          key={type}
          type={type}
          variants={uniqueVariants[type]}
          selectedIndex={selectedVariantIndexes[type]}
          selectedValue={selectedVariantValues[type]}
          onVariantClick={handleVariantClick}
        />
      ))}

      {Object.keys(uniqueVariants).length === 0 && (
        <div className="no-variants">No variants available</div>
      )}
    </div>
  );
}

export default React.memo(VariantList);
