import React from "react";
import { useParams } from "react-router-dom";
import { getModalState } from "../reducer/manageModalStateSlice";
import { useDispatch } from "react-redux";
import { setPId } from "../reducer/productListSlice";
import ImgOrVideo from "./ImgOrVideo";

function ProductsMapList({ productList }) {
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userid");
  const { productId } = useParams();
  // const navigate = useNavigate();
  // const handleProductClick = (productId) => {
  //   navigate(`/productdetails/${userId}/${productId}`);
  // };

  return (
    productList &&
    productList.map((item, index) => {
      return (
        item.id !== productId && (
          <div className="ProductItem" key={index}>
           
            <ImgOrVideo
              url={item.images[0]}
              uid={userId && userId}
              pid={item.id}
            />
            <div className="viewAll">
              {item.images.length > 1 ? (
                <img src="/Images/Icons/Viewall.png" alt="" />
              ) : (
                <div></div>
              )}
              <i
                className="fa-solid fa-ellipsis-vertical "
                data-bs-toggle="modal"
                data-bs-target="#socialshare"
                onClick={() => {
                  dispatch(getModalState(false));
                  dispatch(setPId(item.id));
                }}
                style={{ color: "#fff" }}
              ></i>
            </div>
          </div>
        )
      );
    })
  );
}

export default ProductsMapList;
