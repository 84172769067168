import React, { useEffect } from "react";
import ShareModal from "../components/modal/ShareModal";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apiBaseUrl } from "../config";
import { getUser } from "../reducer/fetchUserSlice";
import SizeChartModal from "../components/modal/SizeChartModal";
import AddToCartModal from "../components/modal/AddToCartModal";
import ProceedToBuyModal from "../components/modal/ProceedToBuyModal";
import ApplyCouponModal from "../components/modal/ApplyCouponModal";
import AddressModal from "../components/modal/AddressModal";

export default function CommonLayout({ children }) {
  const { userId } = useParams();
  const dispatch = useDispatch();
  

  useEffect(() => {
    if (userId) {
      localStorage.setItem("userid", userId);
    }
  }, [userId]);

  const uid = localStorage.getItem("userid");

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(
        `${apiBaseUrl}user/get-user-web/${userId ?? uid}`
      );
      const data = await response.json();
      dispatch(getUser(data?.User));
      localStorage.setItem("storeid",data.User.id )
      localStorage.setItem("storeavater",data.User.profileImg)
      localStorage.setItem('storeemail', data?.User?.email);
      localStorage.setItem('storephone', data?.User?.phone);
    } catch (error) {
      console.error("Error fetching User Details:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  return (
    <>
      {children}
      <ShareModal />
      <SizeChartModal />
      <AddToCartModal  />
      <ProceedToBuyModal />
      <ApplyCouponModal />
      <AddressModal />
    </>
  );
}
