import React, { useEffect } from "react";
import { MainContext } from "./context/MainContext";
import { Routes, Route, useLocation } from "react-router-dom";
import EditProfile from "./pages/EditProfile";
import Home from "./pages/Home";
import AppDownload from "./pages/AppDownload";
import CollectionPage from "./pages/CollectionPage";
import ProductDetails from "./pages/ProductDetails";
import Cart from "./pages/Cart";
import { useSelector } from "react-redux";
import AddPaymentAddress from "./pages/AddPaymentAddress";
import OrderSuccessfull from "./pages/OrderSuccessfull";
import OrderHistoryandSignOut from "./pages/OrderHistoryandSignOut";
import Login from "./pages/Login";
import OTPVerification from "./pages/OTPVerification";
import PaymentFailed from "./pages/PaymentFailed";
import OrderHistory from "./pages/OrderHistory";
import OrderConfirm from "./pages/OrderConfirm";
import ProductPreview from "./pages/ProductPreview";
import CommonProductDt from "./layout/CommonProductDt";
import Kyc from "./pages/Kyc";
import OrderSuccessBuyer from "./components/emailtemplates/OrderSuccessBuyer";

function App() {
  const { pathname } = useLocation();
  const userLogo = useSelector((state) => state.fetchUser?.user?.profileImg);
  const userTitle = useSelector((state) => state.fetchUser?.user?.name);

  useEffect(() => {
    // Function to set or update Open Graph meta tags
    const setMetaTag = (property, content) => {
      let metaTag = document.querySelector(`meta[property='${property}']`);
      if (!metaTag) {
        metaTag = document.createElement("meta");
        metaTag.setAttribute("property", property);
        document.getElementsByTagName("head")[0].appendChild(metaTag);
      }
      metaTag.setAttribute("content", content);
    };

    // Set dynamic Open Graph meta tags for title and image
    if (userTitle) {
      setMetaTag("og:title", userTitle);
    }
    if (userLogo) {
      setMetaTag("og:image", userLogo);
    }

    const setFavicon = (faviconUrl) => {
      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = faviconUrl;
      document.getElementsByTagName("head")[0].appendChild(link);
    };

    if (userLogo) {
      setFavicon(userLogo);
    }

    // Set document title dynamically
    const setTitle = (titleText) => {
      const title =
        document.querySelector("title") || document.createElement("title");
      title.textContent = titleText;
      document.getElementsByTagName("head")[0].appendChild(title);
    };

    if (userTitle) {
      setTitle(userTitle);
    }
  }, [userLogo, userTitle]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const token = localStorage.getItem("token");

  return (
    <MainContext.Provider value={{}}>
      <Routes>
        <Route path="/:userId" element={<Home />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/appdownload" element={<AppDownload />} />
        <Route
          path="/:userId/otp-verification/"
          element={<OTPVerification />}
        />
        <Route path="/:userId/myaccount" element={<OrderHistoryandSignOut />} />
        {/* <Route path="/:userId/myaccount" element={<OrderSuccessBuyer />} /> */}
        <Route path="/:userId/login" element={<Login />} />
        <Route path="collectiondetails" element={<CollectionPage />} />
        <Route path="/:userId/cart" element={<Cart />} />
        <Route path="/:userId/payment-faild" element={<Cart />} />
        <Route
          path="/productdetails/:userId/:productId"
          element={<CommonProductDt />}
        />
        <Route
          path="/:userId/add-payment-address"
          element={<AddPaymentAddress />}
        />
        <Route
          path="/:userId/order-successfull"
          element={<OrderSuccessfull />}
        />
        <Route path="/Kyc" element={<Kyc />} />
        <Route path="/:userId/paymentfaild" element={<PaymentFailed />} />
        <Route path="/:userId/order-history" element={<OrderHistory />} />
        <Route path="/:userId/order-confirm" element={<OrderConfirm />} />
        <Route path="/:userId/product-preview" element={<ProductPreview />} />
      </Routes>
    </MainContext.Provider>
  );
}

export default App;
