import React, { useEffect, useState } from "react";
import location from "../../assets/images/icons/locationIcon.svg";

function AddressModalEdit({ setRefreshAddresses, addressId }) {

  const [loading, setLoading] = useState(true);
  const [addressInfo, setAddressInfo] = useState("");
  const [pincodeInfo, setPincodeInfo] = useState("");
  const [stateInfo, setStateInfo] = useState("");
  const [cityInfo, setCityInfo] = useState("");
  const [emailInfo, setEmailInfo] = useState("");
  const [mobileInfo, setMobileInfo] = useState("");
  const [name, setName] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [errors, setErrors] = useState({});

// error state 
  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");


  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}customer/get-all-addresses/`;
  const token = localStorage.getItem('token');

 // Fetch data when component mounts
 useEffect(() => {
  const fetchAddressData = async () => {

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": token,
        },
      });

      const result = await response.json();
      const matchingAddress = result?.addresses?.find((address) => address._id === addressId);

      if (matchingAddress) {
        // Populate form fields with existing data
        setName(matchingAddress.name);
        setAddressInfo(matchingAddress.address);
        setPincodeInfo(matchingAddress.zip);
        setStateInfo(matchingAddress.state);
        setCityInfo(matchingAddress.city);
        setEmailInfo(matchingAddress.email);
        setMobileInfo(matchingAddress.mobile);
        setLoading(false); // Stop loading when data is fetched
      } else {
        console.error("Error fetching address data:", result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  fetchAddressData();
}, [ apiUrl, token, addressId]);

  
// for validation 
  const validateFields = () => {
    let isValid = true;

    if (!name.trim()) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!addressInfo.trim()) {
      setAddressError("Address is required");
      isValid = false;
    } else {
      setAddressError("");
    }

    if (!pincodeInfo.trim()) {
      setPincodeError("Pincode is required");
      isValid = false;
    } else if (!/^\d{6}$/.test(pincodeInfo)) {
      setPincodeError("Pincode should be 6 digits");
      isValid = false;
    } else {
      setPincodeError("");
    }

    if (!stateInfo.trim()) {
      setStateError("State is required");
      isValid = false;
    } else {
      setStateError("");
    }

    if (!cityInfo.trim()) {
      setCityError("City is required");
      isValid = false;
    } else {
      setCityError("");
    }

    if (!emailInfo.trim()) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInfo)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!mobileInfo.trim()) {
      setMobileError("Mobile number is required");
      isValid = false;
    } else if (!/^\d{10}$/.test(mobileInfo)) {
      setMobileError("Mobile number should be 10 digits");
      isValid = false;
    } else {
      setMobileError("");
    }

    return isValid;
  };

  
// update button submit handeller
  const handleSubmit = async () => {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}customer/edit-address/${addressId}`;
    const token = localStorage.getItem('token'); 
    if (!validateFields()) return;
    const payload = {
      address: addressInfo,
      zip: pincodeInfo,
      state: stateInfo,
      city: cityInfo,
      email: emailInfo,
      mobile: mobileInfo,
      name:name,
      defaultAddress:true
    };

    try {
      const response = await fetch(apiUrl, {
          method: "PUT",
          headers: {
              "Content-Type": "application/json",
              "token": token
          },
          body: JSON.stringify(payload)
      });

      const result = await response.json();

      if (response.ok) {
          setRefreshAddresses(prev => !prev);
          closeModal(); 
      } else {
          console.error("Error add addressing:", result);
      }
  } catch (error) {
      console.error("Error:", error);
  }};

  const clearForm = () => {
    setName("");
    setAddressInfo("");
    setPincodeInfo("");
    setStateInfo("");
    setCityInfo("");
    setEmailInfo("");
    setMobileInfo("");
    setErrors({});
  };

  const closeModal = () => {
    const modalElement = document.getElementById("addressModaledit");
    if (modalElement) {
      modalElement.classList.remove("show");
      modalElement.setAttribute("aria-hidden", "true");
      modalElement.style.display = "none";
  
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
  
      document.body.classList.remove("modal-open");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    
    <div
      className="modal fade"
      id="addressModaledit"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ borderRadius: "20px" }}>
          <div
            className="modal-header"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="d-flex">
              <img src={location} alt="" style={{ marginRight: "16px" }} />
              <p
                className="modal-title"
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#000",
                  alignContent: "center",
                }}
              >
                Selected delivery address:
              </p>
              
            </div>
           
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                border: "none",
                background: "#fff",
                color: "#3371A5",
                fontWeight: "700",
                fontSize: "12px",
                position: "absolute",
                right: "16px",
              }}
              onClick={handleSubmit}
            >
              Update
            </button>
            <hr />
          </div>
          <div className="modal-body"> 
            {/* form start */}
            <div className="d-flex">
              
              <div className="w-100 d-flex flex-column" style={{ gap: "20px" }}>
              <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      background: "#fff",
                      position: "absolute",
                      top: "-8px",
                      left: "16px",
                      fontSize: "12px",
                      color: "#666",
                    }}
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    placeholder="Enter Name"
                    type="text"
                    name="name"
                    id="name"
                    className="addressInput"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (e.target.value.trim()) setNameError("");
                    }}
                  />
                  {nameError && <p style={{fontSize:'12px'}} className="text-danger mb-0 ps-2">{nameError}</p>}
                
                </div>
                
                
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      background: "#fff",
                      position: "absolute",
                      top: "-8px",
                      left: "16px",
                      fontSize: "12px",
                      color: "#666",
                    }}
                    htmlFor="mainAddress"
                  >
                    Address
                  </label>
                  <input
                    placeholder="Enter Address"
                    type="text"
                    name="mainAddress"
                    id="mainAddress"
                    className="addressInput"
                    value={addressInfo}
                    onChange={(e) => {
                      setAddressInfo(e.target.value);
                      if (e.target.value.trim()) setAddressError("");
                    }}
                  />
                  {addressError && <p style={{fontSize:'12px'}} className="text-danger mb-0 ps-2">{addressError}</p>}
                </div>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      background: "#fff",
                      position: "absolute",
                      top: "-8px",
                      left: "16px",
                      fontSize: "12px",
                      color: "#666",
                    }}
                    htmlFor="pincode"
                  >
                    Pincode
                  </label>
                  <input
                    type="number"
                    name="pincode"
                    id="pincode"
                    className="addressInput"
                    placeholder="Pincode"
                    value={pincodeInfo}
                    onChange={(e) => {
                      setPincodeInfo(e.target.value);
                      if (/^\d{5,6}$/.test(e.target.value)) setPincodeError("");
                    }}
                  />
                  {pincodeError && <p style={{fontSize:'12px'}} className="text-danger mb-0 ps-2">{pincodeError}</p>}
                </div>
                <div style={{ display: "flex", gap: "16px" }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      style={{
                        background: "#fff",
                        position: "absolute",
                        top: "-8px",
                        left: "16px",
                        fontSize: "12px",
                        color: "#666",
                      }}
                      htmlFor="state"
                    >
                      State
                    </label>
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      name="state"
                      id="state"
                      className="addressInput"
                      placeholder="State"
                      value={stateInfo}
                      onChange={(e) => {
                        setStateInfo(e.target.value);
                        if (e.target.value.trim()) setStateError("");
                      }}
                    />
                    {stateError && <p style={{fontSize:'12px'}} className="text-danger mb-0 ps-2">{stateError}</p>}
                  </div>

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      style={{
                        background: "#fff",
                        position: "absolute",
                        top: "-8px",
                        left: "16px",
                        fontSize: "12px",
                        color: "#666",
                      }}
                      htmlFor="City"
                    >
                      City
                    </label>
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      name="City"
                      id="City"
                      className="addressInput"
                      placeholder="City"
                      value={cityInfo}
                      onChange={(e) => {
                        setCityInfo(e.target.value);
                        if (e.target.value.trim()) setCityError("");
                      }}
                    />
                    {cityError && <p style={{fontSize:'12px'}} className="text-danger mb-0 ps-2">{cityError}</p>}
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      background: "#fff",
                      position: "absolute",
                      top: "-8px",
                      left: "16px",
                      fontSize: "12px",
                      color: "#666",
                    }}
                    htmlFor="Email"
                  >
                    Email
                  </label>
                  <input
                    placeholder="Enter Communication Email"
                    type="email"
                    name="Email"
                    id="Email"
                    className="addressInput"
                    value={emailInfo}
                    onChange={(e) => {
                      setEmailInfo(e.target.value);
                      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) setEmailError("");
                    }}
                  />
                   {emailError && <p style={{fontSize:'12px'}} className="text-danger mb-0 ps-2">{emailError}</p>}
                </div>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      background: "#fff",
                      position: "absolute",
                      top: "-8px",
                      left: "16px",
                      fontSize: "12px",
                      color: "#666",
                    }}
                    htmlFor="Mobile"
                  >
                    Mobile
                  </label>
                  <input
                    placeholder="Enter Communication Mobile"
                    type="number"
                    name="Mobile"
                    id="Mobile"
                    className="addressInput"
                    value={mobileInfo}
                    onChange={(e) => {
                      setMobileInfo(e.target.value);
                      if (/^\d{10}$/.test(e.target.value)) setMobileError("");
                    }}
                  />
                  {mobileError && <p style={{fontSize:'12px'}} className="text-danger mb-0 ps-2">{mobileError}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressModalEdit;
