import React, { useEffect, useState } from 'react'
import CommonLayout from '../layout/CommonLayout'
import UseHeader from '../components/UserHeader'
import OrderHistoryLayoutOne from '../components/OrderHistoryLayoutOne'
import { useNavigate, useParams } from 'react-router-dom';
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses';

function OrderHistory() {
    const navigate = useNavigate();
    const [orderHistory, setOrderHistory] = useState([]);
    const { userId } = useParams();
    const OrginalOrderId = localStorage.getItem('OrginalOrderId');
    const Pid = localStorage.getItem('pId');

    useEffect(() => {
        const fetchOrderHistory = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}customer/get-orders`, {
                    method: 'GET',
                    headers: {
                        "token": token,
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch order history');
                }
                const data = await response.json();
                setOrderHistory(data?.orders || []);
            } catch (error) {
                console.error('Error fetching order history:', error);
            }
        };

        const updateOrderNumber = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}customer/updateOrderNum`, {
                    method: 'POST',
                    headers: {
                        "token": token,
                    },
                    body: JSON.stringify({
                        "orderId": Pid,
                        "orderNumber": OrginalOrderId
                    })
                }
            );
            } catch (error) {
                console.error('Error updating order number:', error);
            }
        };

        fetchOrderHistory();
        updateOrderNumber();
    }, [OrginalOrderId, Pid, userId]);

    const handleOrderDetails = (orderId) => {
        localStorage.setItem("pId", orderId);
        navigate(`/${userId}/order-confirm`);
    };

    return (
        <CommonLayout>
            <UseHeader />
            {orderHistory.length > 0 ? (
                orderHistory
                    .slice()
                    .reverse()
                    .map((item) => (
                        <OrderHistoryLayoutOne
                            onClick={() => handleOrderDetails(item._id)}
                            key={item._id}
                            ordernumber={item.orderNumber}
                            status={item.orderStatus}
                            date={item.orderDate}
                            amount={item.orderAmount}
                        />
                    ))
            ) : (
                <p className="text-center d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                    <div className="d-block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 24 24">
                            <path
                                style={{ fill: "#3371a5" }}
                                d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm1.336-5l1.977-7h-16.813l2.938 7h11.898zm4.969-10l-3.432 12h-12.597l.839 2h13.239l3.474-12h1.929l.743-2h-4.195z"
                            />
                        </svg>
                        <p>History is empty</p>
                    </div>
                </p>
            )}
        </CommonLayout>
    );
}

export default OrderHistory;
