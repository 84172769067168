import React, { useEffect } from "react";
import CommonLayout from "../layout/CommonLayout";
import BackClick from "../components/BackClick";
import CommonButton from "../components/CommonButton";
import failedicon from "../assets/images/icons/failedicon.svg"
import { useNavigate } from 'react-router-dom';

const  PaymentFailed =()=> {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userid");
  const handleClick = () => {
    navigate(`/${userId}/cart`);
  };
  return (
    <CommonLayout>
      <BackClick />
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "18px",
        }}
      >
        <div style={{ height: "70px", width: "70px" }}>
          <img height={"70px"} width={"70px"} src={failedicon} alt="" />
        </div>
        <h2>Payment Failed</h2>

        <CommonButton name={"Try Again"} width={"80%"} onClick={handleClick}/>
      </div>
    </CommonLayout>
  );
}

export default PaymentFailed;
